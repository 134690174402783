import * as React from "react"

const BranchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 480 480"
    // style={{
    //   enableBackground: "new 0 0 480 480",
    // }}
    xmlSpace="preserve"
  >
    <path d="M472 336h-32v-32a8 8 0 0 0-8-8h-56v-24h40a8 8 0 0 0 8-8v-48a8 8 0 0 0-8-8h-40v-32a8 8 0 0 0-8-8H248v-24h40a8 8 0 0 0 8-8V88a8 8 0 0 0-8-8h-96a8 8 0 0 0-8 8v48a8 8 0 0 0 8 8h40v24H112a8 8 0 0 0-8 8v32H64a8 8 0 0 0-8 8v48a8 8 0 0 0 8 8h40v24H48a8 8 0 0 0-8 8v32H8a8 8 0 0 0-8 8v48a8 8 0 0 0 8 8h80a8 8 0 0 0 8-8v-48a8 8 0 0 0-8-8H56v-24h112v24h-32a8 8 0 0 0-8 8v48a8 8 0 0 0 8 8h80a8 8 0 0 0 8-8v-48a8 8 0 0 0-8-8h-32v-32a8 8 0 0 0-8-8h-56v-24h40a8 8 0 0 0 8-8v-48a8 8 0 0 0-8-8h-40v-24h240v24h-40a8 8 0 0 0-8 8v48a8 8 0 0 0 8 8h40v24h-56a8 8 0 0 0-8 8v32h-32a8 8 0 0 0-8 8v48a8 8 0 0 0 8 8h80a8 8 0 0 0 8-8v-48a8 8 0 0 0-8-8h-32v-24h112v24h-32a8 8 0 0 0-8 8v48a8 8 0 0 0 8 8h80a8 8 0 0 0 8-8v-48a8 8 0 0 0-8-8zM80 352v32H16v-32h64zm128 0v32h-64v-32h64zm-56-128v32H72v-32h80zm48-96V96h80v32h-80zm136 224v32h-64v-32h64zm-8-96v-32h80v32h-80zm136 128h-64v-32h64v32z" />
  </svg>
)

export default BranchIcon