const SpeedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35.933 35.933"
    // style={{
    //   enableBackground: "new 0 0 35.933 35.933",
    // }}
    xmlSpace="preserve"
  >
    <path d="M29.646 10.461A17.88 17.88 0 0 0 17.964 6.13C8.043 6.13 0 14.173 0 24.099c0 .266.026 2.75.038 3.015v2.69H35.86V27.31l.034-.196c.011-.266.039-2.749.039-3.015.001-8.808-6.287-13.638-6.287-13.638zM4.37 27.114c-.015-.164.006-2.549-.005-2.713H8.05v-1.19H4.624c0-.014-.259.002-.259.002.145-3.085 1.32-6.163 3.539-8.59l.093-.1.166.144 2.452 2.454.77-.772-2.605-2.603a13.477 13.477 0 0 1 8.542-3.535l.185-.001s.077-.003.072.046v3.634h.981v-3.679c.484.02.968.077 1.445.152a13.474 13.474 0 0 1 7.352 3.43l-2.6 2.602.723.726 2.497-2.498.138-.111.118.156a13.486 13.486 0 0 1 3.539 8.543h-3.681v1.19l3.698-.005s.003 2.561-.01 2.709l-27.409.009zm18.43-8.586s-5.844 1.792-6.963 2.909a2.866 2.866 0 0 0 .002 4.057 2.864 2.864 0 0 0 4.054 0c1.118-1.122 2.907-6.966 2.907-6.966z" />
  </svg>
)

export default SpeedIcon