import React from 'react';

const EngineIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 295.329 295.329"
    // style={{
    //   enableBackground: "new 0 0 295.329 295.329",
    // }}
    xmlSpace="preserve"
  >
    <path d="m291.416 142.376-33.14-25.16a9.869 9.869 0 0 0-10.383-.98 9.889 9.889 0 0 0-5.492 8.861v15.395h-9.803v-15.559c0-5.465-4.43-9.896-9.893-9.896h-19.696V78.294c0-5.466-4.431-9.896-9.894-9.896h-30.773V57.896h8.284c5.464 0 9.895-4.431 9.895-9.895 0-5.464-4.431-9.893-9.895-9.893h-63.92c-5.463 0-9.894 4.429-9.894 9.893s4.431 9.895 9.894 9.895h8.29v10.502h-38.25c-5.464 0-9.895 4.43-9.895 9.896v19.313H47.528c-5.465 0-9.895 4.43-9.895 9.894v22.634H19.787v-28.073c0-5.464-4.43-9.894-9.894-9.894C4.429 92.167 0 96.598 0 102.061v103.49c0 5.464 4.429 9.893 9.894 9.893 5.464 0 9.894-4.43 9.894-9.893v-28.074h17.847v23.203c0 5.465 4.43 9.894 9.895 9.894h24.881l34.907 42.989a9.896 9.896 0 0 0 7.68 3.656h107.709c5.464 0 9.893-4.432 9.893-9.895v-13.073h9.803v13.073a9.899 9.899 0 0 0 5.758 8.99 9.9 9.9 0 0 0 10.576-1.479l33.141-28.404a9.898 9.898 0 0 0 3.453-7.514v-68.663a9.904 9.904 0 0 0-3.915-7.878z" />
  </svg>
)

export default EngineIcon
