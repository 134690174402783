import React, { useEffect } from "react";
const CHANGETYPENAVVAL = 1024;

export const useCustomEffect = (
  callback: () => void,
  dependecies: any[] = []
) => {
  useEffect(() => {
    // scrolling up
    window.scrollTo(0,0)

    // deleting effect of animation
    if (window.innerWidth > CHANGETYPENAVVAL) {
      const navbar = document.getElementsByClassName(
        "navbar"
      )[0] as HTMLElement;
      navbar.style.animationDelay = "0s";
      callback();
    }
  }, dependecies);
};
