import React, { useEffect } from 'react';
import { useInView } from "react-intersection-observer";


export interface Props {
  power: string;
  description: string;
}

const TextBolid = (props: Props) => {

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView) {
      let element = entry?.target as HTMLElement;
      setTimeout(() => {
        element.style.opacity = "1"
      }, 300)
      
    }
  }, [inView])

  return (
  <div className='text-container' ref={ref}>
    <h1>{props.power}</h1>  
    <h2 dangerouslySetInnerHTML={{__html: props.description}}/>
  </div>
  );
};

export default TextBolid;