import React, { useEffect, useCallback, useState, useRef } from "react";
import "../styles/Navbar.css";
import { useHistory } from "react-router-dom";
import LogoBiale from "../images/LogoBiale.png";
import LogoBialePelne from "../images/LogoBialePelne.png";
import ReactCountryFlag from "react-country-flag";
import NavbarMD from "./NavbarMD";

const CHANGETYPENAVVAL = 1024;

const Navbar = (props: any) => {
  const history = useHistory();
  const navSubsitesRef = useRef<HTMLDivElement | null>(null);
  const countriesMenuRef = useRef<HTMLDivElement | null>(null);
  const [curClicked, setCurClicked] = useState("");
  //const [chosenLanguage, setChosenLanguage] = useState("polish-language");
  const [navbarMD, setNavbarMD] = useState(false);

  const changePageHandler = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    let element = e.target as HTMLElement;

    // change option on not logo elements
    if (element.tagName === "H2" || element.tagName === "IMG") {
      element = element.parentNode as HTMLElement;
    }

    const classNames = element.classList;
    history.push(classNames[1]);

    // const prevElement = document.getElementsByClassName();

    if (curClicked !== "") {
      const prevEl =
        navSubsitesRef.current?.getElementsByClassName(curClicked)[0];

      prevEl?.classList.remove("clicked");
    }
    setCurClicked(classNames[1]);

    element.classList.add("clicked");
	window.scrollTo(0,0);
  };

  const changeLanguage = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    let element = e.target as HTMLElement;
    if (element && element.tagName === "IMG") {
      element = element.parentElement as HTMLElement;
    }
    props.languageHandler.setChosenLanguage(element.id);

    if (countriesMenuRef.current) {
      const children = countriesMenuRef.current.children;

      for (let i = 0; i < children.length; i++) {
        console.log(children[i].classList);
        console.log(children[i].id);
        if (children[i].id === element.id) {
          children[i].classList.add("chosen-language");
        } else {
          children[i].classList.remove("chosen-language");
        }
      }
    }
  };

  const changeTypeNav = () => {
    if (window.innerWidth < CHANGETYPENAVVAL) {
      setNavbarMD(true);
    } else {
      setNavbarMD(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", changeTypeNav);
    changeTypeNav();

    return () => {
      window.removeEventListener("resize", changeTypeNav);
    };
  }, []);

  if (!navbarMD) {
    return (
      <div className="navbar">
        <div className="navbar-logo /" onClick={changePageHandler}>
          <img src={LogoBialePelne} />
        </div>
        <div className="navbar-subsites" ref={navSubsitesRef}>
          {/* <div
            className="subsite-text-container about"
            onClick={changePageHandler}
          >
            <h2 className="subsites-text">
			{props.isEnglish ? "ABOUT US" : "O NAS"}
			</h2>
          </div> */}
          <div
            className="subsite-text-container team"
            onClick={changePageHandler}
          >
            <h2 className="subsites-text">
			{props.isEnglish ? "TEAM" : "ZESPÓŁ"}
			</h2>
          </div>
          <div
            className="subsite-text-container bolid"
            onClick={changePageHandler}
          >
            <h2 className="subsites-text">
			{props.isEnglish ? "OUR CAR" : "BOLID"}
			</h2>
          </div>
          <div
            className="subsite-text-container partners"
            onClick={changePageHandler}
          >
            <h2 className="subsites-text">
			{props.isEnglish ? "PARTNERS" : "PARTNERZY"}
			</h2>
          </div>
          <div
            className="subsite-text-container contact"
            onClick={changePageHandler}
          >
            <h2 className="subsites-text">
			{props.isEnglish ? "CONTACT" : "KONTAKT"}
			</h2>
          </div>
          <div
            className="subsite-text-container countries"
            ref={countriesMenuRef}
          >
            <picture className="chosen-language" id="polish-language">
              <ReactCountryFlag
                countryCode="PL"
                svg
                style={{
                  width: "2.2em",
                  height: "1.65em",
                }}
                title="PL"
                onClick={changeLanguage}
              />
            </picture>
            {/*<picture id="english-language" className="">
              <ReactCountryFlag
                countryCode="GB"
                svg
                style={{
                  width: "2.2em",
                  height: "1.65em",
                }}
                title="GB"
                onClick={changeLanguage}
              />
            </picture>*/}
          </div>
        </div>
      </div>
    );
  } else {
    return <NavbarMD 
	countriesMenuRef = {countriesMenuRef}
	languageHandler={props.languageHandler}
	isEnglish={props.isEnglish}
	/>;
  }
};

export default Navbar;
