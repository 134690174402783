import React, { useEffect, useState, SetStateAction } from "react";
import SponsorsData from "./Interfaces";

// Define the path to your image folder
const imagePath = "../../../../images/";

interface Props {
  page: string;
  setPage: React.Dispatch<SetStateAction<string>>;
  setElementEdit: React.Dispatch<SetStateAction<SponsorsData | undefined>>;
}

const ListSponsors = (props: Props) => {
  const [sponsorsData, setSponsorsData] = useState<SponsorsData[]>();

  const updateSponsors = () => {
    console.log("updateSponsors");
    fetch("/sponsors")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setSponsorsData(data);
        //console.log(imagePath + data[0].filename);
      });
  };

  useEffect(() => {
    console.log(imagePath);
    updateSponsors();
  }, [props.page]);

  const handleDelete = (i: string) => {
    console.log("data");
    fetch(`/sponsors/delete/${i}`)
      .then((response) => response.json())
      .then((data) => {
        //setSponsorsData(data);
        console.log(data);
        updateSponsors();
        //console.log(imagePath + data[0].filename);
      });
  };

  const handleEdit = (el: SponsorsData) => {
    props.setElementEdit(el);
    props.setPage("edit");
  };

  return (
    <div className="list-sponsors">
      <table>
        <tr>
          <th>NAME</th>
          <th>FILENAME</th>
          <th>URL WEBSITE</th>
          <th>TYPE SPONSOR</th>
          <th>EDIT</th>
          <th>DELETE</th>
        </tr>

        {sponsorsData &&
          sponsorsData.map((el, i) => (
            <tr>
              <td>{el.company}</td>
              <td>{el.image}</td>
              <td>{el.urlweb}</td>
              <td>{el.typeSponsor}</td>
              <td>
                <button onClick={() => handleEdit(el)}>EDIT</button>
              </td>
              <td>
                <button onClick={() => handleDelete(el.image)}>DELETE</button>
              </td>
            </tr>
            // <div key={i} className="sponsor">
            //   <h1>{el.name}</h1>
            //   <h1>{el.filename}</h1>
            //   <button onClick={() => handleEdit(el)}>EDIT</button>
            //   <button onClick={() => handleDelete(el.filename)}>DELETE</button>
            //   {/* <img src={`http://localhost:3000/images/${el.filename}`} /> */}
            // </div>
          ))}
      </table>
    </div>
  );
};

export default ListSponsors;
