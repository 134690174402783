import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import "../../styles/MainPage/MainVideo.css";
import Image1 from "../../images/Main/1.jpg";
import Image2 from "../../images/Main/2.jpg";
import Image3 from "../../images/Main/3.jpg";
import Image4 from "../../images/Main/4.jpg";


const MainVideo = () => {
    return (
      <div className="main-video-div">
        <Slide
		  autoplay={true}
		  onChange={function noRefCheck(){}}
		  onStartChange={function noRefCheck(){}}
		  arrows={false}
		  duration={4000}
		  transitionDuration={1500}
		  pauseOnHover={false}
		  easing="ease"
		  //cssClass="main-video"
		>
		  <div className="main-video">
			<div
			  style={{
				backgroundImage: `url(${Image1})`
			  }}
			>
			</div>
		  </div>
		  <div className="main-video">
			<div
			  style={{
				backgroundImage: `url(${Image2})`
			  }}
			>
			</div>
		  </div>
		  <div className="main-video">
			<div
			  style={{
				backgroundImage: `url(${Image3})`
			  }}
			>
			</div>
		  </div>
		  <div className="main-video">
			<div
			  style={{
				backgroundImage: `url(${Image4})`
			  }}
			>
			</div>
		  </div>
	</Slide>
      </div>
    )
}

export default MainVideo;

