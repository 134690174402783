import React from "react";
import BolidMain from "../../images/Bolid/BolidMain.jpg";
import { useCustomEffect } from "../../components/useCustomEffect";
import "../../styles/BolidPage/BolidPage.css";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";
import WUT4 from "../../images/Bolid/WUT4.jpg";
import WUT3 from "../../images/Bolid/WUT3.jpg";
import WUT2 from "../../images/Bolid/WUT2.jpg";
import WUT1 from "../../images/Bolid/WUT1.jpg";
import BolidComponent from "../../components/BolidComponent";
import Footer from "../../components/Footer";

const BolidPage = (props: any) => {
  useCustomEffect(() => {}, []);

  return (
    <div className="bolid-page">
      <div className="main-image">
        <h1>{props.isEnglish ? "OUR CAR" : "BOLID"}</h1>
      </div>
      {/*
      <div className="bolids-animation">
        <div className="circle"></div>
        <div className="firstLine">
          <Controller>
            <Scene
              duration={300}
              pin={{ pushFollowers: true }}
              triggerHook={0.7}
              offset={100}
            >
              {(progress: any) => (
                <Timeline totalProgress={progress} paused>
                  <Tween from={{ height: "0%" }} to={{ height: "100%" }}>
                    <div className="main-line"></div>
                  </Tween>
                </Timeline>
              )}
            </Scene>
          </Controller>

          <div className="gray-background" />
        </div> 
        <div className="WUT3-bolid">
          <div className="bolid-name">
            <h1>WUT-3</h1>
          </div>
          <div className="container">
            <img className="image-bolid" src={WUT2} />
            <div className="bolid-stats"></div>
          </div>
        </div>
      </div> */}
	  
	  <BolidComponent
        srcPhoto={WUT4}
        isMain={false}
        name={"WUT-4"}
        moc={80}
        masa={204}
        przyspieszenie={3.8}
        maks={137}
		isEnglish = {props.isEnglish}
      />
      <BolidComponent
        srcPhoto={WUT3}
        isMain={false}
        name={"WUT-3"}
        moc={78}
        masa={190}
        przyspieszenie={3.8}
        maks={140}
		isEnglish = {props.isEnglish}
      />
      <BolidComponent
        srcPhoto={WUT2}
        isMain={false}
        name={"WUT-2"}
        moc={84}
        masa={245}
        przyspieszenie={4.2}
        maks={140}
		isEnglish = {props.isEnglish}
      />
      <BolidComponent
        srcPhoto={WUT1}
        isMain={false}
        name={"WUT-1"}
        moc={70}
        masa={250}
        przyspieszenie={4.5}
        maks={140}
		isEnglish = {props.isEnglish}
      />
      <Footer isEnglish={props.isEnglish} />
    </div>
  );
};

export default BolidPage;
