import Kornelia_Kołodziejska from "../../images/Team/Zarzad/Kornelia_Kołodziejska.jpg"
import Kamil_Polowczyk from "../../images/Team/Kamil_Polowczyk.jpg"
import Marcin_Płatek from "../../images/Team/Zarzad/Marcin_Płatek.jpg"

interface Person {
    img?: any,
    name: string,
    description?: string,
	email?: string,
    leader?: boolean
}

interface Dzial {
    nameDzial: string,
    leader: Person,
    persons: Person[]
}

let kontakt_os: Person[] = [
	{img: Kornelia_Kołodziejska, name:"Kornelia Kołodziejska", description: "Lider dz. Operacyjnego", email: "kornelia.kolodziejska@wutracing.pl"},
	{img: Marcin_Płatek, name:"Marcin Płatek", description:"Prezes", email: "marcin.platek@wutracing.pl"},
	{img: Kamil_Polowczyk, name: "Kamil Polowczyk", description:"Lider dz. Technicznego", email: "kamil.polowczyk@wutracing.pl"}
]

export type {Dzial, Person};
export default {kontakt_os};