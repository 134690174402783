import React from "react";
import MainVideo from "./MainVideo";
import "../../styles/MainPage/MainPage.css";
import AboutMain from "./AboutMain";
import ManageMain from "./ManageMain";
import BolidyMain from "./BolidyMain";
import PartnersMain from "./PartnersMain";
import Footer from "../../components/Footer";

const MainPage = (props: any) => {
  
  return (
    <div className="main-page">
      <div className="front-page">
        <MainVideo />
        <div style={{color: "white"}} className="front-page-elements">
          <h1>WUT Racing</h1>
          {/* <div className='line'></div> */}
		  <h2>{props.isEnglish ? "We're all connected by a single passion" : "Wszystkich nas łączy jedna pasja"}</h2>
          <h2 id="motorsport-text">Motorsport</h2>
          <div className="line"></div>
        </div>
        {/*<span className="animated-lines">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </span>*/}
      </div>
      <AboutMain isEnglish={props.isEnglish} />
      <ManageMain isEnglish={props.isEnglish} />
      <BolidyMain isEnglish = {props.isEnglish} />
      <PartnersMain isEnglish={props.isEnglish} />
      <Footer isEnglish={props.isEnglish} />
    </div>
  );
};

export default MainPage;
