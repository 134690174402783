import React from 'react'
import LogoBiale from "../images/LogoBiale.png"
import PW_logo from "../images/Sponsorzy/strategiczni/PW.png";
import {FaFacebookF, FaInstagram, FaLinkedin, FaHome} from "react-icons/fa"
import {FiMail} from "react-icons/fi"
import "../styles/footer.css"
import { useHistory } from "react-router-dom";
import PW_footer from "../images/PW_footer.png"

const Footer = (props: any) => {

	const history = useHistory();

	const handleClick = () => {
    history.push("/contact");
  };

  return (
    <div className='footer'>
        <div className='container'>
  
            <div className='contact-address'>
                <h1 onClick={handleClick} >KONTAKT</h1>
                <div className='info'>
                    <div className='addresss'>
                        <div className='first-element'><FaHome/><h2>Politechnika Warszawska</h2></div>
                        <h2>Wydział Mechaniczny Energetyki i&nbsp;Lotnictwa</h2>
                        <h2>ul. Nowowiejska 24</h2>
                        <h2>00-665 Warszawa</h2>
                    </div>
                    <div className='mail-container'>
                        <FiMail/>
                        <h2>zarzad@wutracing.pl</h2>
                    </div>
                    <div className='marketing-container'>
                        <FiMail/>
                        <h2>marketing@wutracing.pl</h2>
                    </div>
                </div>
            </div>
			
			<div className='img-logo'>
                <img src={PW_footer} alt=""/>
            </div>
			
			<div className='contact-media'>
                <h1>ZNAJDŹ NAS</h1>
                <div className='icons-media'>
                    <a href="https://www.facebook.com/WUTRacing/" target="_blank"><FaFacebookF/> </a>
                    <a href="https://www.instagram.com/wut.racing/" target="_blank"><FaInstagram/></a>
                    <a href="https://www.linkedin.com/company/wutracing/" target="_blank"><FaLinkedin/></a>
                </div>
            </div>
			
			{/*<div className='contact-media'>
                <h1>ZNAJDŹ NAS</h1>
                <div className='icons-media'>
                    <a href="https://www.facebook.com/WUTRacing/" target="_blank"><FaFacebookF/> </a>
                    <a href="https://www.instagram.com/wut.racing/" target="_blank"><FaInstagram/></a>
                    <a href="https://www.linkedin.com/company/wutracing/" target="_blank"><FaLinkedin/></a>
                </div>
            </div>*/}
        </div>
    </div>
  )
}

export default Footer
