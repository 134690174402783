import React, { useEffect } from "react";

import "../../styles/MainPage/BolidyPage.css";

import WUT4 from "../../images/Bolid/WUT4.jpg";
import BolidComponent from "../../components/BolidComponent";

const BolidyMain = (props: any) => {
  return (
    <section className="bolid-page">
      <BolidComponent
        srcPhoto={WUT4}
        isMain={true}
        moc={80}
        masa={204}
        przyspieszenie={3.8}
        maks={137}
		isEnglish={props.isEnglish}
      />
    </section>
  );
};

export default BolidyMain;
