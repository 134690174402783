import React, { useEffect, useState } from "react";
import CreateSponsors from "./CreateSponsors";
import ListSponsors from "./ListSponsors";
import "../../styles/AdminPage/AdminPage.css";
import EditSponsor from "./EditSponsor";
import SponsorsData from "./Interfaces";

const AdminPage = () => {
  const [page, setPage] = useState("main");
  const [elementEdit, setElementEdit] = useState<SponsorsData>();

  const createClicked = () => {
    setPage("create");
  };

  if (page === "main") {
    return (
      <div className="admin-page">
        <div className="admin-nav"></div>

        <div className="main-panel">
          <button className="create-sponsor" onClick={createClicked}>
            CREATE
          </button>
          {/* <CreateSponsors /> */}
          <ListSponsors
            page={page}
            setPage={setPage}
            setElementEdit={setElementEdit}
          />
        </div>
      </div>
    );
  } else if (page === "create") {
    return (
      <div className="admin-page">
        <div className="admin-nav"></div>
        <CreateSponsors setPage={setPage} />
      </div>
    );
  } else if (page === "edit") {
    return (
      <div className="admin-page">
        <div className="admin-nav"></div>
        <EditSponsor elementEdit={elementEdit} />
      </div>
    );
  } else {
    return <></>;
  }
};

export default AdminPage;
