import React, { useState } from 'react';
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

export interface Props {
  counter: number;
}

const CounterUp = (props: Props) => {
  const [focus, setFocus] = useState(false)

  return (
    <h3>
    <CountUp start={0} end={props.counter} duration={2} redraw={true}>
      {({ countUpRef }) => (
        <VisibilitySensor
          onChange={(isVisible) => {
            setFocus(isVisible)
          }}
        >
          <span ref={countUpRef} />
        </VisibilitySensor>
      )}
    </CountUp>
  </h3>
  );
};

export default CounterUp;