import React, { SetStateAction, useEffect } from "react";
import SponsorsData from "./Interfaces";

interface Props {
  elementEdit: SponsorsData | undefined;
}

const EditSponsor = (props: Props) => {
  useEffect(() => {
    console.log(props.elementEdit);
  }, []);

  return (
    <div>
      <h1>EDIT</h1>
      <h1>{props.elementEdit?.company}</h1>
      <img src={`http://localhost:3000/images/${props.elementEdit?.image}`} />
    </div>
  );
};

export default EditSponsor;
