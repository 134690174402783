import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import "../styles/Navbar.css";
import LogoBialePelne from "../images/LogoBialePelne.png";
import ReactCountryFlag from "react-country-flag";

const NavbarMD = (props: any) => {
  const history = useHistory();
  // const [slideActive, setSlideActive] = useState(false);
  // const navbarMdRef = useRef<null | HTMLDivElement>(null);

  const activateFallingNav = () => {
    const navbarSite = document.querySelector(".navbar-site");
    const logo = document.querySelector(".navbar-logo") as HTMLElement;
    const width = logo.clientWidth;

    if (navbarSite!.classList.contains("change")) {
      logo.style.marginLeft = `calc(50% - ${width / 2}px)`;
    } else {
      logo!.style.marginLeft = "0";
    }
  };

  const activateNavbarMD = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const element = e.target as HTMLElement;
    const navbarSite = document.querySelector(".navbar-site");

    navbarSite!.classList.toggle("change");
    activateFallingNav();

    // moving to another subsite
    if (element.tagName === "LI") {
      let destination;
      console.log(element.classList);
      if (element.classList.length === 1) {
        destination = element.classList[0];
      } else {
        destination = element.classList[1];
      }

      history.push(destination);
    }
  };

  const moveMainPage = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    history.push("/");
    const navbarSite = document.querySelector(".navbar-site");

    if (navbarSite?.classList.contains("change")) {
      activateNavbarMD(e);
    }
  };
  
  const changeLanguage = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    let element = e.target as HTMLElement;
    if (element && element.tagName === "IMG") {
      element = element.parentElement as HTMLElement;
    }
    props.languageHandler.setChosenLanguage(element.id);

    if (props.countriesMenuRef.current) {
      const children = props.countriesMenuRef.current.children;

      for (let i = 0; i < children.length; i++) {
        console.log(children[i].classList);
        console.log(children[i].id);
        if (children[i].id === element.id) {
          children[i].classList.add("chosen-language");
        } else {
          children[i].classList.remove("chosen-language");
        }
      }
    }
  };

  return (
    <div className="navbar-site page-navbar font-garbled scrolled">
      <div className="page-navbar-holder">
        <div className="navbar-logo" onClick={moveMainPage}>
          <img src={LogoBialePelne} />
        </div>
        <div className="navbar-menu">
          <div className="bar-container" onClick={activateNavbarMD}>
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
        </div>
      </div>
      <div className="falling-nav">
        <ul>
          <li className="border-bottom team" onClick={activateNavbarMD}>
            <a href="team" className="a-nav-element">
              {props.isEnglish ? "TEAM" : "ZESPÓŁ"}
            </a>
          </li>
          <li className="border-bottom bolid" onClick={activateNavbarMD}>
            <a href="bolid" className="a-nav-element">
              {props.isEnglish ? "OUR CAR" : "BOLID"}
            </a>
          </li>
          <li className="border-bottom partners" onClick={activateNavbarMD}>
            <a href="partners" className="a-nav-element">
              {props.isEnglish ? "PARTNERS" : "PARTNERZY"}
            </a>
          </li>
          <li className="contact" onClick={activateNavbarMD}>
            <a href="contact" className="a-nav-element">
              {props.isEnglish ? "CONTACT" : "KONTAKT"}
            </a>
          </li>
        </ul>
		<div
            className="subsite-text-container countries"
            ref={props.countriesMenuRef}
          >
            <picture className="chosen-language" id="polish-language">
              <ReactCountryFlag
                countryCode="PL"
                svg
                style={{
                  width: "2.2em",
                  height: "1.65em",
                }}
                title="PL"
                onClick={changeLanguage}
              />
            </picture>
            {/*<picture id="english-language" className="">
              <ReactCountryFlag
                countryCode="GB"
                svg
                style={{
                  width: "2.2em",
                  height: "1.65em",
                }}
                title="GB"
                onClick={changeLanguage}
              />
            </picture>*/}
          </div>
      </div>
    </div>
  );
};

export default NavbarMD;
