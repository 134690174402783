import React, { useEffect } from "react";
import "../../styles/AboutPage/AboutPage.css";
import About1 from "../../images/About/About1.jpeg";
import About2 from "../../images/About/About2.jpeg";
import { useCustomEffect } from "../../components/useCustomEffect";

const AboutPage = (props: any) => {
  useCustomEffect(() => {
    
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ image: About1 }),
    };

    // console.log("wdada");

    // fetch("http://localhost:5000/sponsors/upload", requestOptions)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log(data);
    //   });
  }, []);
  
  
//if(props?.languageHandler?.chosenLanguage == "english-language")
if(props.isEnglish)
{
  return (
    <div className="about-page">
      {/* <div className="main-image">
        <h1>O NAS</h1>
      </div> */}
      <div className="main-text-container">
        <div className="opening-text">
          <h2>
            <i>
              Jesteśmy elitarnym zespołem składającym się ze studentów
              najlepszych warszawskich uczelni, których celem jest przede
              wszystkim zwycięstwo w zawodach{" "}
              <span className="red-color">Formula Student</span>.
            </i>
          </h2>

          <h2>Heeeeeey I'm in English</h2>
          <ul>
            <li>
              Współpracujemy, aby wspólnymi siłami stworzyć szybki i
              konkurencyjny bolid.
            </li>
            <li>
              Tworzymy maszynę, dzięki której spełnimy nasze marzenia i
              osiągniemy zamierzony cel.
            </li>
          </ul>
        </div>
        <div className="img-container-about">
          <img src={About1} />
          <img src={About2} />
        </div>

        <div className="history-text">
          <div className="history-text-container">
            <h1>
              <span className="red-color">
                <i>Historia</i>
              </span>
            </h1>
          </div>
          <h2>
            Koło naukowe <span className="red-color">WUT Racing</span> powstało
            niespełna dekadę temu, aby studenci Politechniki Warszawskiej mogli
            realizować swoje talenty i pokazać je światu. W kolejnym roku,
            światło dzienne ujrzał pierwszy bolid WUT-1, który stał się
            przepustką na najlepsze tory wyścigowe, na których od lat odbywają
            się zawody Formula Student. Wraz z rozwojem organizacji coraz
            mocniej zaznaczaliśmy naszą obecność w międzynarodowym gronie
            studenckich zespołów wyścigowych.
          </h2>
          <h2>
            W krótkim czasie powstał bolid drugiej generacji, dzięki któremu
            święciliśmy z pewnością największe sukcesy w dotychczasowej
            historii.
          </h2>
          <h2>
            Z czasem <span className="red-color">WUT Racing</span> został
            znacząco rozszerzony o najlepszych studentów z innych warszawskich
            szkół wyższych. W naszych szeregach mamy także osoby na co dzień
            uczące się w m.in. Szkole Głównej Handlowej, które specjalizują się
            w działalności operacyjnej zespołu – logistyce, komunikacji oraz
            pozyskiwaniu funduszy. Podczas gdy stroną techniczną teamu nadal
            kierują głównie reprezentanci Politechniki Warszawskiej.
          </h2>
        </div>
      </div>
    </div>
  );
} else 
	return(<div className="about-page">
      {/* <div className="main-image">
        <h1>O NAS</h1>
      </div> */}
      <div className="main-text-container">
        <div className="opening-text">
          <h2>
            <i>
              Jesteśmy elitarnym zespołem składającym się ze studentów
              najlepszych warszawskich uczelni, których celem jest przede
              wszystkim zwycięstwo w zawodach{" "}
              <span className="red-color">Formula Student</span>.
            </i>
          </h2>

          <h2>Jak Chcemy tego dokonać?</h2>
          <ul>
            <li>
              Współpracujemy, aby wspólnymi siłami stworzyć szybki i
              konkurencyjny bolid.
            </li>
            <li>
              Tworzymy maszynę, dzięki której spełnimy nasze marzenia i
              osiągniemy zamierzony cel.
            </li>
          </ul>
        </div>
        <div className="img-container-about">
          <img src={About1} />
          <img src={About2} />
        </div>

        <div className="history-text">
          <div className="history-text-container">
            <h1>
              <span className="red-color">
                <i>Historia</i>
              </span>
            </h1>
          </div>
          <h2>
            Koło naukowe <span className="red-color">WUT Racing</span> powstało
            niespełna dekadę temu, aby studenci Politechniki Warszawskiej mogli
            realizować swoje talenty i pokazać je światu. W kolejnym roku,
            światło dzienne ujrzał pierwszy bolid WUT-1, który stał się
            przepustką na najlepsze tory wyścigowe, na których od lat odbywają
            się zawody Formula Student. Wraz z rozwojem organizacji coraz
            mocniej zaznaczaliśmy naszą obecność w międzynarodowym gronie
            studenckich zespołów wyścigowych.
          </h2>
          <h2>
            W krótkim czasie powstał bolid drugiej generacji, dzięki któremu
            święciliśmy z pewnością największe sukcesy w dotychczasowej
            historii.
          </h2>
          <h2>
            Z czasem <span className="red-color">WUT Racing</span> został
            znacząco rozszerzony o najlepszych studentów z innych warszawskich
            szkół wyższych. W naszych szeregach mamy także osoby na co dzień
            uczące się w m.in. Szkole Głównej Handlowej, które specjalizują się
            w działalności operacyjnej zespołu – logistyce, komunikacji oraz
            pozyskiwaniu funduszy. Podczas gdy stroną techniczną teamu nadal
            kierują głównie reprezentanci Politechniki Warszawskiej.
          </h2>
        </div>
      </div>
    </div>);
};


export default AboutPage;
