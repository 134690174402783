import React, { Dispatch, SetStateAction, useState } from "react";

const CreateSponsors = ({
  setPage,
}: {
  setPage: React.Dispatch<SetStateAction<string>>;
}) => {
  const [company, setCompany] = useState("");
  const [urlWeb, setUrlWeb] = useState("");
  const [typeSponsor, setTypeSponsor] = useState("");

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    console.log("www");

    fetch("/sponsors/upload", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        // Handle the response
        console.log(response);
        setPage("main");
      })
      .catch((error) => {
        // Handle errors
        console.error(error);
      });
    console.log("finito");
  };

  return (
    <>
      <h1>CREATE PANEL</h1>
      <form
        className="form-create"
        method="POST"
        action="/sponsors/upload"
        encType="multipart/form-data"
        onSubmit={handleSubmit}
      >
        <input
          type="text"
          name="company"
          placeholder="company"
          value={company}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setCompany(e.target.value)
          }
        />
        <label className="custom-file-upload">
          <input type="file" name="image" />
          Custom Upload
        </label>
        <input
          type="text"
          name="webUrl"
          placeholder="urlWeb"
          value={urlWeb}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setUrlWeb(e.target.value)
          }
        />
        {/* <input
          type="text"
          name="sponsorType"
          placeholder="sponsor type"
          value={typeSponsor}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setTypeSponsor(e.target.value)
          }
        /> */}
        <select name="sponsorType">
          <option value="strategiczny">strategiczny</option>
          <option value="glowny">główni</option>
          <option value="zaufali">zaufali nam</option>
        </select>
        <input type="submit" />
      </form>
    </>
  );
};

export default CreateSponsors;
