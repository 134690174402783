// strategiczni
import Mechaniczny from "../../images/Sponsorzy/strategiczni/MEL.png";
//import ModelArt from "../../images/Sponsorzy/strategiczni/modelart.png";
import Politechnika from "../../images/Sponsorzy/strategiczni/politechnika.png";
import SIMR from "../../images/Sponsorzy/strategiczni/SIMR.png";
import WE_PW from "../../images/Sponsorzy/strategiczni/WE.png";
import WIM from "../../images/Sponsorzy/strategiczni/WIM.png";
import WEITI from "../../images/Sponsorzy/strategiczni/WEITI.jpg";

//glowni
import Airtech from "../../images/Sponsorzy/glowni/airtech.png";
import Alcar from "../../images/Sponsorzy/glowni/Alcar.jpg";
import Allweld from "../../images/Sponsorzy/glowni/Allweld.jpg";
import Amazemet from "../../images/Sponsorzy/glowni/Amazemet.png";
import Arjan from "../../images/Sponsorzy/glowni/Arjan.png";
import Ataszek from "../../images/Sponsorzy/glowni/Ataszek.png";
import Balcia from "../../images/Sponsorzy/glowni/Balcia.png";
import Befana from "../../images/Sponsorzy/glowni/befana.jpg";
import Bibus from "../../images/Sponsorzy/glowni/Bibus.png";
import Bosmal from "../../images/Sponsorzy/glowni/Bosmal.jpg";
import Bossard from "../../images/Sponsorzy/glowni/Bossard.png";
import Cubicinch from "../../images/Sponsorzy/glowni/Cubicinch.png";
import Dostawcze24 from "../../images/Sponsorzy/glowni/Dostawcze24.png";
import Edag from "../../images/Sponsorzy/glowni/Edag.png";
import ElesaGanter from "../../images/Sponsorzy/glowni/elesa-ganter.png";
import Fraikin from "../../images/Sponsorzy/glowni/Fraikin.png";
import Katcon from "../../images/Sponsorzy/glowni/Katcon.png";
import Kistenberg from "../../images/Sponsorzy/glowni/Kistenberg.jpg";
import KonsorcjumStali from "../../images/Sponsorzy/glowni/KonsorcjumStali.png";
import Milar from "../../images/Sponsorzy/glowni/Milar.png";
import Nilfisk from "../../images/Sponsorzy/glowni/Nilfisk.png";
import Niuton from "../../images/Sponsorzy/glowni/Niuton.png";
import Oberon from "../../images/Sponsorzy/glowni/Oberon.png";
import RallyShop from "../../images/Sponsorzy/glowni/RallyShop.png";
import Rexxer from "../../images/Sponsorzy/glowni/Rexxer.png";
import SB_PW from "../../images/Sponsorzy/glowni/SB_PW.png";
import Spes3D from "../../images/Sponsorzy/glowni/Spes3D.png";
import Todamex from "../../images/Sponsorzy/glowni/Todamex.png";
import Warstal from "../../images/Sponsorzy/glowni/Warstal.png";

/*import Ansys from "../../images/Sponsorzy/glowni/ansys.jpg";
import Asmet from "../../images/Sponsorzy/glowni/asmet.png";
import AtlasCopco from "../../images/Sponsorzy/glowni/atlas-copco.png";
import Bosch from "../../images/Sponsorzy/glowni/bosch.png";
import CarbonTec from "../../images/Sponsorzy/glowni/carbon-tec.png";
import CentrumZarzadzania from "../../images/Sponsorzy/glowni/centrum-zarzadzania.png";
import Defum from "../../images/Sponsorzy/glowni/defum.png";
import DevilCars from "../../images/Sponsorzy/glowni/devil-cars.png";
import DiamentoweAuto from "../../images/Sponsorzy/glowni/diamentowe-auto.png";
import EasyComposites from "../../images/Sponsorzy/glowni/easy-composites.png";
import Elkor from "../../images/Sponsorzy/glowni/Elkor.png";
import Igus from "../../images/Sponsorzy/glowni/igus.png";
import InterMetal from "../../images/Sponsorzy/glowni/inter-metal.png";
import Konigstal from "../../images/Sponsorzy/glowni/konigstal.png";
import MasterModel from "../../images/Sponsorzy/glowni/mastermodel.png";
import MawoTechnology from "../../images/Sponsorzy/glowni/mawo-technology.png";
import Maximus from "../../images/Sponsorzy/glowni/maximus.png";
import Millenium from "../../images/Sponsorzy/glowni/millenium.png";
import Palimet from "../../images/Sponsorzy/glowni/palimet.png";
import PolishAcademy from "../../images/Sponsorzy/glowni/polish-academy.png";
import Prema from "../../images/Sponsorzy/glowni/prema.jpg";
import Skiba from "../../images/Sponsorzy/glowni/skiba.jpg";
import Skrawit from "../../images/Sponsorzy/glowni/skrawit.jpg";
import SpeedFloor from "../../images/Sponsorzy/glowni/speed-floor.png";
import Stomilex from "../../images/Sponsorzy/glowni/stomilex.png";
import TomSteel from "../../images/Sponsorzy/glowni/tomsteel.png";
import Usign from "../../images/Sponsorzy/glowni/usign.jpg";
import Warstal from "../../images/Sponsorzy/glowni/warstal.png";
import Zortax from "../../images/Sponsorzy/glowni/Zortax.jpg";*/

//zaufali
import Connexion from "../../images/Sponsorzy/zaufali/3dconnexion.png";
import Adves from "../../images/Sponsorzy/zaufali/adves.png";
import Aim from "../../images/Sponsorzy/zaufali/aim.png";
import Aircraftica from "../../images/Sponsorzy/zaufali/aircraftica.png";
import Arrinera from "../../images/Sponsorzy/zaufali/arrinera.png";
import AutodromSlomczyn from "../../images/Sponsorzy/zaufali/autodrom-slomczyn.png";
import Autodrom from "../../images/Sponsorzy/zaufali/autodrom.png";
import AutomobilRzemeslnik from "../../images/Sponsorzy/zaufali/automobilklub-rzemeslnik.jpg";
import Awih from "../../images/Sponsorzy/zaufali/awih.png";
import Bakalland from "../../images/Sponsorzy/zaufali/bakalland.jpg";
import DaviesCraig from "../../images/Sponsorzy/zaufali/davies-craig.png";
import EcuMaster from "../../images/Sponsorzy/zaufali/ecu-master.png";
import Fanar from "../../images/Sponsorzy/zaufali/fanar.png";
import Fiberlogy from "../../images/Sponsorzy/zaufali/fiberlogy.png";
import FmicEu from "../../images/Sponsorzy/zaufali/fmic_eu.png";
import Havel from "../../images/Sponsorzy/zaufali/havel.png";
import Henkel from "../../images/Sponsorzy/zaufali/henkel.png";
import Hertz from "../../images/Sponsorzy/zaufali/hertz.jpg";
import IpmaPolska from "../../images/Sponsorzy/zaufali/ipma-polska.png";
import Kongsberg from "../../images/Sponsorzy/zaufali/kongsberg.jpg";
import Lincoln from "../../images/Sponsorzy/zaufali/lincoln.jpg";
import Mesco from "../../images/Sponsorzy/zaufali/MESco.png";
import Motamec from "../../images/Sponsorzy/zaufali/motamec.jpg";
import MotoPark from "../../images/Sponsorzy/zaufali/moto-park.png";
import Motorus from "../../images/Sponsorzy/zaufali/motorus.jpg";
import Motul from "../../images/Sponsorzy/zaufali/motul.png";
import Multiwizja from "../../images/Sponsorzy/zaufali/multiwizja.png";
import OpakowaniaEksportowe from "../../images/Sponsorzy/zaufali/opakowania-eksportowe.png";
import Orlen from "../../images/Sponsorzy/zaufali/orlen.jpg";
import Panek from "../../images/Sponsorzy/zaufali/panek.jpg";
import Pemes from "../../images/Sponsorzy/zaufali/pemes.png";
import PentlaSquad from "../../images/Sponsorzy/zaufali/pentla-squad.png";
import Petrostar from "../../images/Sponsorzy/zaufali/petrostar.png";
import PizzaBrakes from "../../images/Sponsorzy/zaufali/pizza_brakes.jpg";
import PolPoz from "../../images/Sponsorzy/zaufali/pol-poz.png";
import PracowniaScenografia from "../../images/Sponsorzy/zaufali/pracownia-scenografia.png";
import Procnc from "../../images/Sponsorzy/zaufali/procnc.jpg";
import Prozone from "../../images/Sponsorzy/zaufali/prozone.png";
import Pufy from "../../images/Sponsorzy/zaufali/pufy.png";
import RaceTcs from "../../images/Sponsorzy/zaufali/Race_TCS.png";
import RedBull from "../../images/Sponsorzy/zaufali/red-bull.jpg";
import Spolnota from "../../images/Sponsorzy/zaufali/spolnota.png";
import Ssab from "../../images/Sponsorzy/zaufali/ssab.svg";
import Sutfpol from "../../images/Sponsorzy/zaufali/sutfpol.png";
import Techniko from "../../images/Sponsorzy/zaufali/techniko.jpg";
import Textreme from "../../images/Sponsorzy/zaufali/textreme.png";
import ThyssenKrupp from "../../images/Sponsorzy/zaufali/thyssenkrupp.png";


interface Sponsor {
  img: any,
  url: string
}


let strategiczni: Sponsor[] = [
  //{img: Politechnika, url:"https://www.pw.edu.pl/"},
  {img: Mechaniczny, url:"https://www.meil.pw.edu.pl//"},
  {img: SIMR, url:"https://www.simr.pw.edu.pl/"},
  {img: WIM, url:"https://www.wim.pw.edu.pl/"},
  {img: WE_PW, url:"https://www.ee.pw.edu.pl/"},
  {img: WEITI, url:"https://www.elka.pw.edu.pl/"}
  //{img: ModelArt, url:"http://www.modelart.com.pl/"},
]

let glowni: Sponsor[] = [
  {img: Airtech, url:"https://airtech.lu/"},
  {img: Alcar, url:"https://www.facebook.com/alcarpl/"},
  {img: Allweld, url:"https://allweld.pl/"},
  {img: Amazemet, url:"https://www.amazemet.com/"},
  {img: Arjan, url:"https://sklep-metale.pl/"},
  {img: Ataszek, url:"https://ataszek.pl/"},
  {img: Balcia, url:"https://www.balcia.pl/pl/"},
  {img: Befana, url:"https://www.befana.com.pl/"},
  {img: Bibus, url:"https://www.bibusmetals.pl/"},
  {img: Bosmal, url:"https://www.bosmal.com.pl/"},
  {img: Bossard, url:"https://www.bossard.com/pl-pl/"},
  {img: Cubicinch, url:"https://cubicinch.pl/"},
  {img: Dostawcze24, url:"https://dostawcze24.pl/"},
  {img: Edag, url:"https://www.edag.com/en/"},
  {img: ElesaGanter, url:"https://www.elesa-ganter.pl/pl/pol"},
  {img: Fraikin, url:"https://www.fraikin.pl/pl"},
  {img: Katcon, url:"https://katcon.pl/"},
  {img: Kistenberg, url:"https://www.kistenberg.com/pl"},
  {img: KonsorcjumStali, url:"https://www.konsorcjumstali.pl/"},
  {img: Milar, url:"https://www.milar.pl/"},
  {img: Nilfisk, url:"https://www.nilfisk.com/pl-pl/"},
  {img: Niuton, url:"https://niuton.com.pl/"},
  {img: Oberon, url:"http://www.oberon.pl/"},
  {img: RallyShop, url:"https://rallyshop.pl/"},
  {img: Rexxer, url:"https://www.rexxer.pl/"},
  {img: SB_PW, url:"https://biznes.edu.pl/"},
  {img: Spes3D, url:"https://spes3d.pl/"},
  {img:Techniko, url:"https://www.techniko.pl/"},
  {img: Todamex, url:"https://todamex.pl/"},
  {img: Warstal, url:"https://warstal.pl/"},
 
  /*{img: Ansys, url:""},
  {img: Asmet, url:"https://asmet.com.pl/"},
  {img: AtlasCopco, url:"https://www.atlascopco.com/pl-pl"},
  {img: Bosch, url:"https://www.bosch.pl/"},
  {img: CarbonTec, url:"https://carbon-tec.eu/"},
  {img: CentrumZarzadzania, url:"https://www.cziitt.pw.edu.pl/"},
  {img: Defum, url:"https://www.afm.com.pl/"},
  {img: DevilCars, url:"https://devil-cars.pl/"},
  {img: DiamentoweAuto, url:"https://diamentoweauto.pl/"},
  {img: EasyComposites, url:"https://www.easycomposites.co.uk/"},
  {img: Elkor, url:"http://elkor.biz/"},
  {img: Igus, url:"https://www.igus.pl/"},
  {img: InterMetal, url:"http://www.inter-metal.com.pl/"},
  {img: Konigstal, url:"https://www.koenigstahl.pl/pl/"},
  {img: MasterModel, url:"https://mastermodel.pl/"},
  {img: MawoTechnology, url:"https://mawotechnology.com/"},
  {img: Maximus, url:"https://www.maximuscnc.com/pl/"},
  {img: Millenium, url:"https://www.bankmillennium.pl/"},
  {img: PolishAcademy, url:"https://www.unipress.waw.pl/"},
  {img: Palimet, url:"https://palimet.com/"},
  {img: Prema, url:"https://prema.com.pl/"},
  {img: Skiba, url:"http://www.firmaskiba.pl/"},
  {img: Skrawit, url:"https://www.skrawit.pl/"},
  {img: SpeedFloor, url:"https://speedfloor.pl/"},
  {img: Stomilex, url:"https://stomilex.pl/"},
  {img: TomSteel, url:"http://tomsteel-cnc.com/pl/"},
  {img: Usign, url:""},
  {img: TomSteel, url:"http://tomsteel-cnc.com/pl/"},
  {img: Warstal, url:"https://warstal.pl/"},
  {img: Zortax, url:"https://zortrax.com/pl/"},*/
]


let zaufali: Sponsor[] = [
  {img:Connexion, url:"https://3dconnexion.com/pl/"},
  {img:Adves, url:""},
  {img:Aim, url:"https://www.aim-sportline.com/"},
  {img:Aircraftica, url:"https://aircraftica.com/en/"},
  {img:Arrinera, url:"http://www.arrinera.com/"},
  {img:AutodromSlomczyn, url:"http://autodromslomczyn.pl/"},
  {img:Autodrom, url:"https://torjastrzab.pl/"},
  {img:AutomobilRzemeslnik, url:"http://www.ak-rzemieslnik.pl/index.html"},
  {img:Awih, url:"https://awih.pl/"},
  {img:Bakalland, url:"https://bakalland.pl/"},
  {img:DaviesCraig, url:"https://daviescraig.com.au/"},
  {img:EcuMaster, url:"https://www.ecumaster.com/"},
  {img:Fanar, url:"https://www.fanar.pl/"},
  {img:Fiberlogy, url:"https://fiberlogy.com/pl/"},
  {img:FmicEu, url:"https://fmic.pl/"},
  {img:Havel, url:"http://www.havel-composites.pl/"},
  {img:Henkel, url:"https://www.henkel.pl/"},
  {img:Hertz, url:"https://www.hertz.com/rentacar/reservation/"},
  {img:IpmaPolska, url:"https://ipma.pl/"},
  {img:Kongsberg, url:"https://www.kongsbergautomotive.com/"},
  {img:Lincoln, url:"https://www.lincolnelectric.com/pl-pl/Pages/default.aspx"},
  {img:Mesco, url:"https://mesco.com.pl/"},
  {img:Motamec, url:"https://motamec.com/"},
  {img:MotoPark, url:"https://motopark.com.pl/"},
  {img:Motorus, url:"https://www.motorus.pl/"},
  {img:Multiwizja, url:"http://www.multiwizja.eu/"},
  {img:OpakowaniaEksportowe, url:"https://opakowaniaeksportowe.pl/"},
  {img:Orlen, url:"https://www.orlen.pl/pl"},
  {img:Motul, url:"https://www.motul.com/pl/pl"},
  {img:Panek, url:"https://panekcs.pl/"},
  {img:Pemes, url:""},
  {img:PentlaSquad, url:"https://www.facebook.com/Pentlasquad/"},
  {img:Petrostar, url:"https://www.petrostar.pl/"},
  {img:PizzaBrakes, url:"https://pizzabrakes.pl/"},
  {img:PolPoz, url:"https://pol-poz.pl/"},
  {img:PracowniaScenografia, url:"http://www.pracowniascenografa.pl/"},
  {img:Procnc, url:"http://procnc.pl/"},
  {img:Prozone, url:"https://www.prozone.pl/"},
  {img:Pufy, url:"https://pufy.pl/"},
  {img:RaceTcs, url:"https://pufy.pl/"},
  {img:RedBull, url:"https://www.redbull.com/pl-pl/"},
  {img:Spolnota, url:"http://www.spolnota.pl/"},
  {img:Ssab, url:"https://www.ssab.com/pl-pl"},
  {img:Sutfpol, url:"http://www.surfpol.pl/"},
  {img:Textreme, url:"https://www.textreme.com/"},
  {img:ThyssenKrupp, url:""},
]


export type {Sponsor};
export default {strategiczni, zaufali, glowni}