import React, { useEffect } from "react";
import "../styles/MainPage/BolidyPage.css";
import { useInView } from "react-intersection-observer";
import AccelerationIcon from "../icons/AccelerationIcon";
import EngineIcon from "../icons/EngineIcon";
import SpeedIcon from "../icons/SpeedIcon";
import WeightIcon from "../icons/WeightIcon";
import TextBolid from "../components/TextBolid";
import { useHistory } from "react-router-dom";

interface Props {
  srcPhoto: any;
  isMain: boolean;
  name?: string;
  moc: number;
  masa: number;
  przyspieszenie: number;
  maks: number;
  isEnglish: boolean;
}

const BolidComponent = ({
  srcPhoto,
  isMain,
  name,
  moc,
  masa,
  przyspieszenie,
  maks,
  isEnglish,
}: Props) => {
  const history = useHistory();
  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0.9,
  });

  const [imageRef, imageInView, imageEntry] = useInView({
    /* Optional options */
    threshold: 0.9,
  });

  useEffect(() => {
    if (inView) {
      let children = entry?.target;
      children?.classList.add("bolidy-header-appear");
    }
  }, [inView]);

  useEffect(() => {
    if (imageInView) {
      let redLines = imageEntry?.target.children[1] as HTMLElement;

      // for (let i = 0; i < redLines.children.length; i++) {
      //   let child = redLines.children[i] as HTMLElement;
      //   child.style.height = "100%";
      // }
      //redLines.style.height = "120%";
    }
  }, [imageInView]);

  const handleClickButton = () => {
    history.push("/bolid");
  };

  return (
    <div className="bolidy-front-page">
      {isMain ? (
        <div className="bolidy-header" ref={ref}>
          <h1>NASZE KONSTRUKCJE</h1>
        </div>
      ) : (
        <div className="bolidy-header not-main" ref={ref}>
          <h1>{name}</h1>
        </div>
      )}

      <div className="bolid-info">
        <div className="bolid-image" ref={imageRef}>
          <img src={srcPhoto} />
          <div className="red-lines">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        <div className="straight-line"></div>
        <div className="bolid-stats">
          <div>
            <EngineIcon />
            <TextBolid power={isEnglish ? `${moc} hp` : `${moc} KM`} description={isEnglish ? "engine power" : "moc silnika"} />
          </div>
          <div>
            <SpeedIcon />
            <TextBolid
              power={`${maks} km/h`}
              description={isEnglish ? "top speed" : "prędkość maksymalna"}
            />
          </div>
          <div>
            <AccelerationIcon />
            <TextBolid
              power={`${przyspieszenie} s`}
              description={isEnglish ? "acceleration 0-100 km/h" : "przyspieszenie<br />0-100 km/h"}
            />
          </div>
          <div>
            <WeightIcon />
            <TextBolid power={`${masa} kg`} description={isEnglish ? "weight" : "masa własna"} />
          </div>
        </div>
      </div>
      {isMain && (
        <button className="wut-button bolid-button" onClick={handleClickButton}>
          {isEnglish ? "OTHER CARS" : "POZOSTAŁE KONSTRUKCJE"}
        </button>
      )}
    </div>
  );
};

export default BolidComponent;
